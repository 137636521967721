import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./DeleteUser.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Form, Col, Row } from "react-bootstrap";
import { Banner1 } from "../../../assets/images";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Checkbox } from "../../../components/Website/Forms";


const DeleteUser = (props: any) => {
  const [show, setShow] = useState(true);
  const [show2, set2Show] = useState(true);


  return (
    <>
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>How to Delete Account</h2>
          </div>
        </div>
      </section>

      <section className={`${st.sectionPadding}`}>
        <Container>
          <Row>
            <Col lg={12} className={`m-auto`}>
          <div className={`${cx.mainContainer}`}>
            <div className={`${cx.stepCol}`}>
              <div className={`${cx.steps}`}>Step 1</div>
              <img src="https://taximo.ca/customer/login.jpg" alt="Delete Your Account" />
              <div className={`${cx.labels}`}>Login to App</div>
            </div>
            <div className={`${cx.stepCol}`}>
              <div className={`${cx.steps}`}>Step 2</div>
              <img src="https://taximo.ca/customer/menu.jpg" alt="Delete Your Account" />
              <div className={`${cx.labels}`}>Open Side Bar</div>
            </div>
            <div className={`${cx.stepCol}`}>
              <div className={`${cx.steps}`}>Step 3</div>
              <img src="https://taximo.ca/customer/deleteUser.jpg" alt="Delete Your Account" />
              <div className={`${cx.labels}`} style={{color:'red'}}>Delete Account</div>
            </div>
          </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DeleteUser;



// const DeleteUser: React.FC = () => {
//   const [htmlContent, setHtmlContent] = useState<string>('');

//   useEffect(() => {
//     const fetchHtmlContent = async () => {
//       try {
//         const response = await fetch('https://taximo.ca/customer/pages/privacy_policy');
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const html = await response.json();
//         console.log(html.data, "htmll")
//         setHtmlContent(html.data);
//       } catch (error) {
//         console.error('There was a problem with the fetch operation:', error);
//       }
//     };

//     fetchHtmlContent();
//   }, []);

//   return (
//     <div
//       dangerouslySetInnerHTML={{ __html: htmlContent }}
//     />
//   );
// };

// export default DeleteUser;

